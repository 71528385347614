import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image"
import { useSpring, useSprings, animated } from "react-spring"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import styled from "styled-components"

import { breakpoints } from "./../assets/globalStyles"
import Burger from "./Burger"

const HeaderWrapper = styled.header`
  padding: 0 16px;
  background-color: #d42b69;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${breakpoints.md}px) {
    height: 112px;
    padding: 0 48px;
    .mobile-menu-button {
      display: none;
    }
  }
  > a {
    display: inline-flex;
  }
`

const DesktopNav = styled.nav`
  display: none;
  @media screen and (min-width: ${breakpoints.md}px) {
    display: flex;
    height: 100%;
  }
`

const MobileNav = styled(animated.nav)`
  background-color: "#D42B69";
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 73px;
  z-index: 3;
  overflow: auto;
  padding: 40px 0;
  @media screen and (min-width: ${breakpoints.md}px) {
    display: none;
  }
  a:hover {
    color: white;
  }
`

const MobileNavItem = styled(Link)`
  display: block;
  padding: 8px 28px;
  border-left-color: ${({ active }) => (active ? "#ffffff" : "transparent")};
  border-left-width: 4px;
  border-left-style: solid;
  text-decoration: none;
  color: #ffffff;
  font-family: Poppins;
  font-size: 26px;
  line-height: 40px;
  font-weight: ${({ active }) => (active ? 600 : "")};
`

const TopBar = styled(animated.div)`
  background-color: white;
  height: 4px;
  margin-bottom: 32px;
`

const Wrapper = styled(Link)`
  margin-left: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  text-decoration: none;
  line-height: 48px;
  &:hover {
    color: #ffffff;
  }
`

export default function Header() {
  const {
    wpMenu: {
      menuItems: { nodes: menuItems },
    },
    wp: {
      options: {
        optionsPageFields: {
          logos: {
            lightLogo: {
              localFile: {
                childImageSharp: { desktopLogo, mobileLogo },
              },
            },
          },
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
        wp {
          options {
            optionsPageFields {
              logos {
                lightLogo {
                  localFile {
                    childImageSharp {
                      mobileLogo: gatsbyImageData(
                        width: 36
                        height: 48
                        placeholder: NONE
                        layout: FIXED
                      )
                      desktopLogo: gatsbyImageData(
                        height: 32
                        placeholder: NONE
                        layout: FIXED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const [showMenu, setShowMenu] = useState(false)

  // animation for opening sidebar
  const mobileMenuAnimation = useSpring({
    transform: showMenu ? "translateX(0px)" : "translateX(100%)",
  })

  //Lock body and scroll various elements inside the sidebar
  useEffect(() => {
    if (typeof document === "undefined") return

    if (showMenu) {
      disableBodyScroll(document.getElementById("mobileNav"))
    } else {
      enableBodyScroll(document.getElementById("mobileNav"))
    }
  }, [showMenu])

  const [hoveredIndex, setHoveredIndex] = useState()

  const topBarAnimations = useSprings(
    menuItems.length,
    menuItems.map(({ url }, index) => ({
      width:
        hoveredIndex === index ||
        (typeof window !== "undefined" &&
          window.location.pathname.includes(url))
          ? "100%"
          : "0%",
      config: {
        clamp: true,
        friction: 12,
      },
    }))
  )

  //combine animation properties with menu items
  const animatedMenuItems = menuItems.map((menuItem, index) => ({
    ...menuItem,
    animationProps: topBarAnimations[index],
  }))

  return (
    <>
      <HeaderWrapper>
        <Link to="/">
          <GatsbyImage
            image={withArtDirection(desktopLogo, [
              {
                media: `(max-width: 914px)`,
                image: mobileLogo,
              },
              {
                media: `(min-width: 915px)`,
                image: desktopLogo,
              },
            ])}
            objectPosition="left"
            alt="Daffodil Marketing Logo"
            objectFit="contain"
            loading="eager"
          />
        </Link>

        <Burger
          className="mobile-menu-button"
          onClick={() => {
            setShowMenu(!showMenu)
          }}
          isOpen={showMenu}
        />

        <DesktopNav>
          {animatedMenuItems.map(({ label, url, animationProps }, index) => (
            <Wrapper
              onMouseEnter={() => {
                setHoveredIndex(index)
              }}
              onMouseLeave={() => {
                setHoveredIndex()
              }}
              key={url}
              to={url}
            >
              <TopBar style={animationProps} />

              {label}
            </Wrapper>
          ))}
        </DesktopNav>
      </HeaderWrapper>
      <MobileNav id="mobileNav" style={mobileMenuAnimation}>
        {menuItems.map(({ label, url }) => (
          <MobileNavItem
            key={url}
            to={url}
            active={
              typeof window !== "undefined" &&
              window.location.pathname.includes(url)
                ? 1
                : 0
            }
            onClick={() => {
              setShowMenu(false)
            }}
          >
            {label}
          </MobileNavItem>
        ))}
      </MobileNav>
    </>
  )
}
